import React from 'react';
import cx from 'classnames';
import messengerIcon from 'assets/images/socials/messenger.svg';
import telegramIcon from 'assets/images/socials/telegram.svg';
import viberIcon from 'assets/images/socials/viber.svg';
import whatsappIcon from 'assets/images/socials/whatsapp.svg';
import { MESSENGER_LINKS } from 'constants/contacts';
import { OnlineCallButton } from './OnlineCallButton';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const WidgetPopup = ({ opened, onClose }: IProps) => {
  return (
    <div className={cx('widget-popup', opened && 'opened')}>
      <div className="popup-header">
        <button className="btn-nostyle" onClick={onClose}>
          <svg
            className="close-icon"
            width="16"
            height="16"
            viewBox="0 0 23 23"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fillRule="evenodd">
              <path d="M0 1.414L1.415 0l21.213 21.213-1.414 1.414z" />
              <path d="M21.213 0l1.414 1.415L1.414 22.628 0 21.214z" />
            </g>
          </svg>
        </button>
      </div>
      <div className="popup-content">
        <div className="title">Напишите нам в удобный для Вас мессенджер</div>
        <div className="social-links">
          <a href={MESSENGER_LINKS.viber} className="social-link">
            <img src={viberIcon} alt="Viber" />
            <span>Viber</span>
          </a>
          <a
            href={MESSENGER_LINKS.whatsApp}
            className="social-link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={whatsappIcon} alt="Whatsapp" />
            <span>Whatsapp</span>
          </a>
          <a
            href={MESSENGER_LINKS.messenger}
            className="social-link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={messengerIcon} alt="Messenger" />
            <span>Messenger</span>
          </a>
          <a
            href={MESSENGER_LINKS.telegram}
            className="social-link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={telegramIcon} alt="Telegram" />
            <span>Telegram</span>
          </a>
        </div>
        <div className="bottom-text">Или позвоните нам!</div>
        <OnlineCallButton />
        <div className="instruction-text">
          <span className="asterisk">*</span>Для звонка обязательно разрешите
          использование микрофона
        </div>
      </div>
    </div>
  );
};

export default WidgetPopup;
